import $ from 'jquery';
import 'core-js';

export default class Tabs {
  constructor () {
      this.$tab = $('.js-tab');
      this.$item = $('.js-list');
  }

  init () {
    this.tabs();
  }

  tabs () {
      let tab = this.$tab;
      let item = this.$item;

      var mq = window.matchMedia( "(max-width: 980px)" );
      if (mq.matches) {
          console.log('fasdfasdf')
          $('.js-list').removeClass('active')
          $('.js-list-show').hide();

          item.on('click', function (event) {

              $('.js-list').removeClass('active');
              $('.js-list-show').each(function () {
                  if($(this).css('display') == 'block') {
                      $(this).hide()
                  }
              });
              if ($(this).parent().find('.js-list-show').css('display')=='block'){
              } else {
                  $(this).parent().find('.js-list-show').show();
                  $(this).addClass('active');
              }
          });
      }
      else {
          tab.on('click', function (event) {
              tab.removeClass('active');
              $(this).addClass('active');
              $('.c-tab-box').hide();
              $('#content-'+$(this).data('content')).show();
          });

          item.on('click', function (event) {

              $(this).closest('.js-tab-box').find('.js-list').removeClass('active');
              $(this).closest('.js-tab-box').find('.js-list-show').each(function () {
                  if($(this).css('display') == 'block') {
                      $(this).hide()
                  }
              });
              if ($(this).parent().find('.js-list-show').css('display')=='block'){
              } else {
                  $(this).parent().find('.js-list-show').show();
                  $(this).addClass('active');
              }
          });
      }



  }

}


