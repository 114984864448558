import $ from 'jquery';
export default class Header {
  constructor () {
    this.$hamburger = $('.js-trigger-nav-main');
    this.$header = $('.js-sec-header');
  }

  init () {
    this.scroll();
    this.video();
    this.goTo();
    this.menuMobile();
  }

  changeSizeHeader () {
    const scrollTop = $(window).scrollTop();
    let newHeight;

    newHeight = 100;

    if (scrollTop > newHeight) {
      this.$header.addClass('is-scrolled');
    } else {
      this.$header.removeClass('is-scrolled');
    }
  }

  video () {
      $('#myvideo').hover(function toggleControls() {
          if (this.hasAttribute("controls")) {
              this.removeAttribute("controls");
          } else {
              this.setAttribute("controls", "controls");
          }
      });

      $('.c-video').click(function () {
          // if ($("#myvideo").get(0).paused) {
          //     $('#myvideo').trigger('play');
          // } else {
          //     $('#myvideo').trigger('pause');
          // }
      })
  }


  scroll () {
    this.changeSizeHeader();
    $(document).on('scroll', (e) => {
      this.changeSizeHeader();
        var scrollPos = $(document).scrollTop();
        $('.js-sec-header ul a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr('href'));
            if (refElement.position().top-60 <= scrollPos) {
                $('.js-sec-header ul li a').removeClass("active");
                currLink.addClass("active");
            }
            else{
                currLink.removeClass("active");
            }
        });
    });
  }

    menuMobile() {
        this.$hamburger.click(function() {
            $(this).next().toggleClass('active');
            $(this).toggleClass('active');
            return false;
        });
    }

  goTo () {
      let hamburger = this.$hamburger;
      $('.js-scroll-to').on('click', function (event) {
          console.log('aaaddfsdfe33333');
          var target = $(this.getAttribute('href'))
          if ($(this).hasClass('c-nav-list__label')) {
              hamburger.next().toggleClass('active');
              hamburger.toggleClass('active');
          }
          if ($(this).hasClass('js-vid')) {
              console.log('aaa');
              document.getElementById("myvideo").play();
              if (target.length) {
                  event.preventDefault()

                  $('html, body').stop().animate({
                      scrollTop: $('#myvideo').offset().top-50
                  }, 800)
              }
          } else {
              if (target.length) {
                  event.preventDefault()

                  $('html, body').stop().animate({
                      scrollTop: target.offset().top-50
                  }, 800)
              }
          }

      })
  }
}


