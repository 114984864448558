import $ from "jquery";

$( document ).ready( function() {

    var movies = {
            default: 'files/video/oko.mp4',
            hover: 'files/video/top-skompresowany.mp4'
        };
    var texts = {
            interval:null,
            pointer:0,
            default:'zauważać',
            library:[
                "jeść ciepły obiad",
                "wspólne czytanie",
                "odrabiać lekcje",
                "oglądać filmy",
                "robić herbatę",
                "bawić się z innymi",
                "przytulać się ",
                "uśmiechać się ",
                "uczyć się języka ",
                "grać w zagadki",
                "zmywać naczynia",
                "sprzątać pokój",
                "czytać gazetę",
                "uczyć się pisać",
                "robić kolację",
                "słuchać muzykę",
                "tańczyć w kuchni",
                "rozmawiać z kimś ",
                "nie martwić się ",
                "mówić dzień dobry",
                "pokazywać język",
                "przybijać piątkę",
                "ćwiczyć sport",
                "kibicować innym",
                "wspierać się ",
                "opowiadać kawały",
                "śpiewać piosenki",
                "pójść na spacer",
                "pomagać innym",
                "myśleć o wszystkich"
            ]
        };

    function changeTextLoop() {
        changeText(texts.library[texts.pointer]);
        texts.pointer++;
        if (texts.pointer >= texts.library.length-1) texts.pointer = 0;
    }

    function startShow() {
        texts.pointer = 0;
        changeTextLoop();
        changeMovie(movies.hover);
        texts.interval = setInterval(changeTextLoop, 240);
    }

    function changeText($new) {
        $('#top-swipe-text p span').html($new);
    }

    function changeMovie($src) {
        $('#top video').attr('src', $src);
    }

    function stopShow() {
        changeMovie(movies.default);
        clearInterval(texts.interval);
        changeText(texts.default);
    }

    $('#top-swipe-text').hover(startShow, stopShow);

    $('p').each(function() {
        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g,"$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
});